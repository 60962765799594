import React from "react"
import { PrismicRichText } from '@prismicio/react';

import bird from '../../../assets/images/bird-1.webp';
import gearbox from '../../../assets/images/gearbox.webp';

import ButtonGetStarted from '../../Buttons/ButtonGetStarted'
import ButtonWatchDemo from '../../Buttons/ButtonWatchDemo'

import "./ComparisonPageHero.css"

const imageMap = { bird, gearbox };

const HeroSection = ({
  slice: {
    primary: {
      title: { richText: title, text: titleText },
      description: { richText: description },
      hero_image: heroImage,
      brief,
    },
  },
}) => (
  <div className="relative pt-32 lg:pt-0 pb-24 lg:pb-0 lg:min-h-[56rem] lg:h-[100vh]">
    <div className="max-w-7xl mx-auto flex flex-col md:items-center justify-center md:text-center px-4 z-10 relative h-full">
      {brief && (
        <div className="text-lg uppercase text-green-carriyo font-medium font-header pb-3 m-0 tracking-wider">
          {brief}
        </div>
      )}
      <PrismicRichText
        field={title}
        components={{
          heading1: ({ children }) => (
            <h1 className="xl:text-8xl lg:text-7xl sm:text-6xl text-5xl text-blue font-medium tracking-tighter mb-8">
              {children}
            </h1>
          ),
        }}
      />
      <PrismicRichText
        field={description}
        components={{
          paragraph: ({ children }) => (
            <p className="text-blue text-xl mb-3">
              {children}
            </p>
          ),
        }}
      />
      <div className="btns flex justify-center md:items-center md:flex-row flex-col gap-2 md:gap-4 text-center">
        <ButtonGetStarted />
        <ButtonWatchDemo />
      </div>
    </div>
    <img
      alt={titleText}
      className={`ComparisonPageHero-image ComparisonPageHero-image--${heroImage}`}
      src={imageMap[heroImage]}
    />
  </div>
);

export default HeroSection;
