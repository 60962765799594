import { graphql } from "gatsby"
import HeroSection from '../ComparisonPageV2/shared/HeroSection';

export default HeroSection;

export const query = graphql`
  fragment ComparisonPageHeroSlice on PrismicComparisonPageDataBodyHero {
    id
    slice_type
    primary {
      title {
        richText
        text
      }
      description {
        richText
      }
      hero_image
    }
  }
`;
