import React from "react";
import { graphql } from "gatsby";

import { SliceZone } from "@prismicio/react"
import { components } from "../components/ComparisonPage"
import SEO from "../components/seo"
import Layout from "../components/layout"

const ComparisonPage = (props) => {
  const { data } = props
  if (!data) return null
  const pageData = data.prismicComparisonPage
  const page = pageData.data || {}
  return (
    <Layout className="background1">
      <SEO
        context={props.pageContext}
        cpath={props.location.pathname}
      />
      <SliceZone
        slices={page.body}
        components={components}
        context={pageData}
        defaultComponent={() => null}
      />
    </Layout>
  );
};

export default ComparisonPage;

export const query = graphql`
  query comparisonPageQuery ($uid: String!){
    prismicComparisonPage (uid: {eq: $uid}) {
      id
      type
      data {
        body{
          ... on PrismicSliceType {
            slice_type
          }
          ...ComparisonPageHeroSlice
          ...ComparisonPageFeaturesSlice
          ...ComparisonPageSalesSlice
        }
      }
    }
  }
`
