import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';

import Testimonials1 from "../Elements/Testimonials1"

const Testimonials = ({ children, theme = 'dark' }) => {
  const { siteSettings: { data: site }, testimony } = useStaticQuery(graphql`
    query {
      siteSettings : prismicSitesettings {
        data {
          testimonial_content_desktop { richText }
          testimonial_content_mobile { richText }
        }
      }
      testimony : allPrismicTestimonials {
        edges {
          node {
            uid
            data {
              author
              content
              designation
              image {
                fluid {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  `);
  const {
    background,
    textColor,
  } = {
    dark: {
      background: 'bg-gradient',
      textColor: 'text-white',
    },
    light: {
      background: 'bg-white',
      textColor: 'text-blue',
    },
  }[theme];
  return (
    <div className={`${background} xl:pt-32 md:pt-24 pt-16 pb-56 teamtestimonial relative`}>
      {children}
      <div className="ml-auto flex lg:flex-row flex-col relative">
        <div className="w-full px-4 hidden md:block">
          <PrismicRichText
            field={site.testimonial_content_desktop.richText}
            components={{
              heading2: ({ children }) => (
                <h2 className={`xl:w-96 lg:pl-4 lg:ml-auto xl:text-6xl text-5xl ${textColor} font-medium tracking-tighter mb-12`}>
                  {children}
                </h2>
              ),
            }}
          />
        </div>
        <div className="w-full px-4 md:hidden">
          <PrismicRichText
            field={site.testimonial_content_mobile.richText}
            components={{
              heading2: ({ children }) => (
                <h2 className={`xl:w-96 lg:pl-4 lg:ml-auto text-6xl md:text-7xl ${textColor} font-medium tracking-tighter mb-4`}>
                  {children}
                </h2>
              ),
              paragraph: ({ children }) => (
                <p className={`${textColor} sm:text-2xl text-xl`}>
                  {children}
                </p>
              ),
            }}
          />
        </div>
        <div className="lg:w-2/3 w-full relative">
          <Testimonials1
            data={testimony.edges.map(({ node }) => node).filter(({ data }) => data?.image?.fluid).map((document) => ({ testimonial: { document } }))}
            theme={theme}
          />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
