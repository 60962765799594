import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react";

import { FormButton } from "../../frontend-shared/components";

import Link from "../Elements/Link";

const FeaturesSection = ({ slice: { items } }) => (
  <div className="py-16 relative z-10 -mt-32 px-4 mx-auto max-w-7xl">
    <div className="grid gap-16 lg:gap-y-32 my-16 lg:items-center lg:grid-cols-[repeat(3,_minmax(auto,_50%))]">
      {(items || []).map(({
        button_link,
        button_text,
        description,
        poster,
        title,
      }, index)=> {
        const imageBeforeText = index % 2;
        const button = button_text && (
          <Link className="no-underline" href={button_link}>
            <FormButton color="secondary" size="md">
              {button_text}
            </FormButton>
          </Link>
        );
        const text = (
          <>
            <h3 className="text-blue lg:text-5xl md:text-4xl text-2xl font-medium mb-0 tracking-tighter">
              {title}
            </h3>
            <PrismicRichText
              field={description?.richText}
              components={{
                paragraph: ({ children }) => (
                  <p className="xl:text-xl lg:text-lg text-md text-blue mb-1">
                    {children}
                  </p>
                ),
              }}
            />
            <div className="hidden lg:block">{button}</div>
          </>
        );
        const translation = imageBeforeText ? 'translate-x-1/6' : '-translate-x-1/2';
        const image = (
          <img
            alt={poster?.alt}
            className={`drop-shadow-xl min-w-[768px] lg:min-w-0 transform ${translation} md:translate-x-0 m-0`}
            loading="lazy"
            srcSet={poster?.fluid?.srcSetWebp.split('w,').at(-1).split(' ').at(0)}
          />
        );
        const [first, second, firstClass, secondClass] = imageBeforeText ? [
          image, text, 'col-span-2', 'col-span-1',
        ] : [
          text, image, 'col-span-1', 'col-span-2',
        ];
        return(
          <React.Fragment key={index}>
            <div className={`hidden lg:flex flex-col gap-8 ${firstClass}`}>{first}</div>
            <div className={`hidden lg:flex flex-col gap-8 ${secondClass}`}>{second}</div>
            <div className="flex max-w-md sm:max-w-xl md:max-w-2xl lg:hidden flex-col gap-8">
              <div>{text}</div>
              <div className="md:hidden">{button}</div>
              <div className="w-full">{image}</div>
              <div className="hidden md:block">{button}</div>
            </div>
          </React.Fragment>
        )
      })}
    </div>
  </div>
);

export default FeaturesSection;

export const query = graphql`
  fragment ComparisonPageFeaturesSlice on PrismicComparisonPageDataBodyFeatures {
    id
    slice_type
    items {
      button_link
      button_text
      title
      description {
        richText
      }
      poster {
        alt
        fluid(maxWidth: 2400, maxHeight: 2400) {
          srcSet
          srcSetWebp
        }
      }
    }
  }
`

