import React from "react"
import square from "../../../assets/images/shipment.png";

import { FormButton } from "../../../frontend-shared/components";

import CustomerExperience from "../../Elements/CustomerExperience";
import Link from "../../Elements/Link";
import SectionGradient from "../../Elements/SectionGradient";
import Testimonials from "../../Elements/Testimonials";

import './ComparisonPageSales.css';

const SalesSection = ({
  slice: {
    primary: {
      button_text,
      button_link,
      section_title: title,
    },
  },
}) => (
  <>
    <div className="relative w-full h-32">
      <SectionGradient color="white" position="bottom" />
    </div>
    <div className="relative bg-white w-full z-10">
      <div className="w-full flex flex-col md:items-center gap-8 md:gap-12 relative z-10 pt-16 px-4 pb-32">
        <h2 className="xl:text-6xl text-5xl font-medium tracking-tighter m-0 text-blue">
          {title}
        </h2>
        {button_text && (
          <Link className="no-underline" href={button_link}>
            <FormButton color="primary" size="lg">
              {button_text}
            </FormButton>
          </Link>
        )}
      </div>
      <Testimonials theme="light" />
      <div className="z-1">
        <img className="ComparisonPageSales-icon1" src={square} />
        <img className="ComparisonPageSales-icon2" src={square} />
      </div>
    </div>
    <div className="bg-gradient xl:py-32 md:py-24 py-16 text-white">
      <CustomerExperience items="clients" />
    </div>
  </>
);

export default SalesSection;
